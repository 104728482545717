/* eslint-disable react/prop-types */
import React from "react"
import Grid from "@material-ui/core/Grid"
import Link from "next/link"
import Carousel from "../../Carousel"
import { Rating } from "@material-ui/lab"
import { Typography } from "@material-ui/core"
import { imageSrcHelper } from "settings"

import clsx from "clsx"
import css from "./card.module.scss"

const Card = ({
  slug,
  name,
  city,
  description,
  reviews = [],
  averageRating = -1,
  thumbnails,
  photoGallery,
  types = [],
}) => {
  const type = types.filter((el) => ["restaurant", "caffe", "lodging", "establishment"].includes(el))

  return (
    <Link href="/location/[...slug]" as={`/location/${slug}` || "err"}>
      <div
        className={clsx(css.dishCard, css[type[0]])}
        style={{
          cursor: "pointer",
          background:
            thumbnails?.length > 0
              ? `url(${imageSrcHelper(thumbnails[0], "small")}) no-repeat center center / cover`
              : null,
        }}
      >
        {averageRating && averageRating > -1 && (
          <Grid container alignItems="center" className={css.cardRating}>
            <Rating value={averageRating} readOnly size="small" precision={0.5} />
            <Typography variant="body2" display="inline">
              ({reviews.length})
            </Typography>
          </Grid>
        )}
        <Grid container>
          {photoGallery?.length > 0 && <Carousel slides={photoGallery} />}
          <Grid item xs={10} className={css.cardTitle}>
            <h3>{name || ``}</h3>
          </Grid>
          {city && (
            <Grid item xs={10} className={css.cardCity}>
              <h5>{city || ``}</h5>
            </Grid>
          )}
          {/* <Grid item xs={3} className={css.cardPrice}>
          <p>{rating || ``}</p>
        </Grid> */}
        </Grid>

        <Grid item className={css.cardDescription}>
          {/* <Grid
          item
          md={12}
          dangerouslySetInnerHTML={{ __html: description || `` }}
        /> */}
        </Grid>
      </div>
    </Link>
  )
}

export default Card
