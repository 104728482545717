import React from "react"
import Slider from "react-slick"
import "./style.module.scss"

const Carousel = ({ slides, settings = {} }) => {
  settings = {
    dots: true,
    ...settings,
  }

  return (
    <Slider {...settings} className="slider">
      {slides?.map((img) => (
        <div key={Math.random()}>
          <img src={`${img.publicURL}`} draggable="false" alt="" />
        </div>
      ))}
    </Slider>
  )
}

export default Carousel
